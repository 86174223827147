import axios from '@/axios'
const route = "register/input"
export default {

    getAll(ItemsPerPage, page, InputGroupId, search) {
        return axios.get(route + '?page=' + page + '&items_per_page=' + ItemsPerPage + '&input_group_id=' + InputGroupId + '&search=' + search)
    },
    store(stage) {
        return axios.post(route, stage)
    },

    update(stage) {
        return axios.put(route + '/' + stage.id, stage)
    },

    destroy(stage) {
        return axios.delete(route + '/' + stage.id)
    },

    getAllInputGroup() {
        return axios.get(route + '/get_all_input_group')
    },

}
