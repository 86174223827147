<template>
  <v-container data-app>
    <v-data-table
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions:[5,10,15],
        showFirstLastPage:true
      }"
      sort-by="corporate_name"
      class="elevation-1"
    >
     <!-- formatacao de codigo completo  -->
      <template v-slot:item.full_code="{ item }">
        <div style="white-space:nowrap">{{ item.input_group.code+'-'+item.full_code }} </div>
      </template>
      <!-- formatacao de valor com encargo -->
      <template v-slot:item.value_with_tax="{ item }">
        {{ formatMoney(item.value_with_tax) }}
      </template>
      <!-- formataco de data validacao -->
      <template v-slot:item.validation="{ item }">
        {{ formatDate(item.validation) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Insumos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Localizar"
              @click:append="searchItems()"
              @change="searchItems()"
              single-line
              hide-details
              class="mr-8"
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                rounded
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                ><v-icon small>mdi-plus</v-icon>Novo insumo</v-btn
              >
            </template>
            <v-card>
              <div class="title_body">
                  <span class="text_title text-h6">{{ formTitle }}</span>
                  <span class="btn_close"><v-btn icon dark @click="dialog = false" ><v-icon dark > mdi-close </v-icon></v-btn> </span>
             </div>
              <v-card-text>
                <v-container>
                  <v-form class="px-3" @submit.prevent="submit()">
                    <v-row>
                      <v-col cols="2">
                        <v-text-field

                          dense
                          v-model="editedItem.full_code"
                          label="Codigo"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          dense
                          v-model="editedItem.name"
                          label="Nome"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.input_group_id"
                          :loading="loadingCompositionGroup"
                          :disabled="loadingCompositionGroup"
                          dense
                          :items="itemsAllCompositionGroup"
                          label="Grupo"
                          item-text="name"
                          item-value="id"
                          ></v-select>
                          </v-col>
                      <v-col>
                        <v-select
                          v-model="editedItem.type"
                          dense
                          :items="itemsType"
                          label="Tipo"
                        ></v-select>

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.unity"
                          dense
                          :items="items_unity"
                          label="Unidade"
                        ></v-select>
                        </v-col>

                      <v-col>
                        <v-menu
                          v-model="menu_date2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              v-model="formatData"
                              label="Validação"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker

                            v-model="editedItem.validation"
                            @input="menu_date2 = false"
                          ></v-date-picker>
                        </v-menu>

                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <vuetify-money
                        label="Valor"
                        dense
                        v-model="value"
                        :clearable="true"
                        valueWhenIsEmpty=""
                        :options="{ locale: 'pt-BR',prefix:'R$', length: 11,  precision: 5}"
                        />

                        </v-col>
                        <v-col>
                        <vuetify-money
                        label="Encargos"
                        dense
                        v-model="tax"
                        :clearable="true"
                        valueWhenIsEmpty=""
                        :options="{ locale: 'pt-BR',suffix:'%', length: 11,  precision: 5}"
                        />

                      </v-col>
                      <v-col>
                         <vuetify-money
                        label="Valor com Encargos"
                        :readonly="true"
                        dense
                        v-model="editedItem.value_with_tax"
                        :clearable="false"
                        valueWhenIsEmpty=""
                        :options="{ locale: 'pt-BR',prefix:'R$', length: 11,  precision: 5}"
                        />

                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mx-0 mt-3 mr-1" @click="dialog = false"
                  >Fechar</v-btn
                >
                <v-btn
                  class="primary mx-0 mt-3"
                  :loading="btnLoadingSave"
                  :disabled="btnLoadingSave"
                  @click="save"
                  >Salvar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="text-h5"
                >Tem certeza de que deseja excluir este item ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" @click="closeDelete">Cancelar</v-btn>
                <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>
  </v-container>
</template>

<script>
import service from "./service";
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    totalStagesPaginate : 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate:0,
    options: {},
    search:'',
    value:"",
    tax:"",

    loadingCompositionGroup:false,
    itemsAllCompositionGroup:[],
    menu_date2: false,
    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Codigo Completo",
        align: "start",
        sortable: false,
        value: "full_code",
      },
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "type" },
      { text: "Unidade", value: "unity" },
      { text: "Validade", value: "validation" },
      { text: "Valor Com Encargos (R$)", value: "value_with_tax" },
      { text: "", value: "actions", align:'end', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      input_group_id: "",
      type: "",
      full_code: "",
      name: "",
      unity: "",
      tax: "",
      validation: "",
      value: "",
      value_with_tax: "",
    },
    defaultItem: {
      id: "",
      input_group_id: "",
      type_id: "",
      full_code: "",
      name: "",
      unity: "",
      tax: "",
      validation: "",
      value: "",
      value_with_tax: "",
    },
    items_unity: [
      "m",
      "m²",
      "m³",
      "g",
      "kg",
      "ton",
      "l",
      "h",
      "dia",
      "sem",
      "mês",
      "unid",
      "fl",
      "cento",
      "vb",
      "bd",
      "caixa",
      "pç",
      "jg",
      "rl",
      "vg",
      "gl",
      "lt",
      "mil",
      "pct",
      "sc",
    ],
    itemsType:[
      'Mão de Obra',
      'Material',
      'Contrato',
      'EPI',
      'Despesa Indireta',
      'Locação',
      'Frete',
      'Sub-Emp',
      'Etapa',
      'Serviço',
      ]
  }),

  computed: {
    ...mapGetters('InputGroup',['getInputGroupId']),
    formatData(){
        let date = this.editedItem.validation
        if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar Insumo" : "Editar Insumo";
    },
  },

  watch: {
    dialog:{
      handler() {
        if(this.editedIndex === -1){
          //se cadastrar insumo novo já seta o grupo do insumo
          this.editedItem = Object.assign({},this.defaultItem)
          this.editedItem.input_group_id = this.getInputGroupId


        }
      }
    },
    value:{
      handler() {
        this.editedItem.value = this.value
        this.calcValorTotal()
      },

    },
    tax:{
      handler() {
        this.editedItem.tax = this.tax
        this.calcValorTotal()
      }
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      //carrega a tabela
      this.loading_data_table = true;
      const { page, itemsPerPage } = this.options;

      let inputGroupId = this.getInputGroupId
      service.getAll(itemsPerPage||10,page,inputGroupId,this.search).then((response) => {
        this.desserts = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });
     this.loadingCompositionGroup = true
     //carrga o select grupo de composicao
     service.getAllInputGroup().then((response) => {
        this.itemsAllCompositionGroup = response.data;
        this.loadingCompositionGroup = false
     });

    },
    searchItems(){
      this.options = {}
      this.initialize()

    },
    editItem(item) {
      this.tax = item.tax
      this.value = item.value
      this.value_with_tax = item.value_with_tax
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      service.destroy(this.editedItem).then(() => {
        this.desserts.splice(editedIndex, 1);
        this.$toast.success("Excluído com sucesso.");
      });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.tax ="";
        this.value ="";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.btnLoadingSave = true;
      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;
        service
          .update(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            Object.assign(this.desserts[indice], response.data);
            this.close();
            this.$toast.success("Salvo com sucesso.");
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      } else {
        //store
        service
          .store(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            this.desserts.push(response.data);
            this.close();
            this.$toast.success("Salvo com sucesso.");
            if (this.desserts.length == 1){ this.initialize()}
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      }
    },
    calcValorTotal(){
       let tax = this.parseNumber(this.editedItem.tax)/100
       this.editedItem.value_with_tax = (this.parseNumber(this.editedItem.value) * tax) + this.parseNumber(this.editedItem.value)

    },
    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    formatMoney(number){
      number = this.parseNumber(number)
      return number.toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2})
    },
    formatDate(date) {
      let date_split = date.split("-");
      return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
    },
  },
};
</script>


